import * as React from 'react';
import { object, string } from 'yup';
import { postUsersConfirmation } from 'api/userAuthentication';
import { Button } from 'styleguide/components';
import FormWrapper from '../FormWrapper/FormWrapper';
import { H4 } from 'styleguide/components/Heading';
import { Status } from 'libs/utils/api/types';
import { signInRedirectPath } from 'bundles/App/routes';
import { Form, Formik, FormikErrors, FormikValues } from 'formik';
import Loader from 'styleguide/components/Loader/Loader';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import { useHistory } from 'react-router';
import { FormikFieldWrapper } from 'app/styleguide/components/Formik';

const ResendConfirmationFormSchema = object().shape({
  email: string()
    .strict()
    .email('Please enter a valid email.')
    .trim('Email cannot begin or end with spaces')
    .required('Email is required.'),
});

const ResendConfirmationPage = () => {
  const history = useHistory();
  const resendConfirmation = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    postUsersConfirmation(values).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        history.push(signInRedirectPath);
      }
    });
  };

  return (
    <FormWrapper>
      <H4 className="-sm:mt-4' mb-4 !text-center !font-hvBold !text-4xl !font-bold">Resend confirmation</H4>
      <Formik
        initialValues={{ email: '' }}
        onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
          resendConfirmation(values, setStatus, setSubmitting, setErrors);
        }}
        validationSchema={ResendConfirmationFormSchema}
      >
        {formikProps => (
          <Form>
            <FormikFieldWrapper
              data-cy="email"
              type="email"
              name="email"
              componentType="input"
              labelComponent="Email"
              wrapperClassName="mb-4"
            />
            {formikProps.isSubmitting ? (
              <Loader />
            ) : (
              <Button
                dataCy="submitFormBtn"
                className="w-[360px] !px-0 -sm:w-[320px]"
                type="button"
                onClick={() => formikProps.submitForm()}
                color="blue"
              >
                Resend Confirmation
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};
export default ResendConfirmationPage;
